import { useState, useEffect } from "react";
import { Box, Slide } from "@mui/material";

export default function Header(props) {
    const { mainRef } = props;

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (mainRef.current) {
                const { scrollTop } = mainRef.current;

                if (scrollTop > 230) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };

        if (mainRef.current) {
            mainRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (mainRef.current) {
                mainRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }
        , [mainRef]);


    return (
        <Slide direction="down" in={isVisible} mountOnEnter unmountOnExit>
            <Box sx={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 1000,
                background: "rgba(0,0,0, 0.8)",
                width: "100%",
                height: "100px",
                display: "flex",
                paddingLeft: "20px",
                alignItems: "center",
                backdropFilter: "blur(10px)",
            }}>
                <svg width="150" height="69" viewBox="0 0 150 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M145.373 6.05452C145.373 4.54213 145.373 3.02975 145.373 1.51736C140.808 1.82559 136.271 2.32966 131.761 3.02975C131.761 2.52567 131.761 2.02144 131.761 1.51736C136.731 0.447954 141.772 -0.0562756 146.885 0.00497603C147.29 2.42328 146.785 4.4399 145.373 6.05452Z" fill="#E48784" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M100.001 7.56683C99.4998 21.7184 100.004 35.8339 101.514 49.9136C102.53 47.7219 103.538 45.7054 104.538 43.8641C104.538 46.3848 104.538 48.9053 104.538 51.426C102.522 51.426 100.505 51.426 98.4889 51.426C98.4889 40.8393 98.4889 30.2526 98.4889 19.6659C98.0255 15.3021 98.5296 11.269 100.001 7.56683Z" fill="#FEB6B5" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M66.7287 12.1042C66.7287 14.6249 66.7287 17.1454 66.7287 19.6661C64.4935 21.0991 62.9812 23.1157 62.1915 25.7157C62.5973 21.7541 62.0932 17.9732 60.6791 14.3728C54.6396 14.1152 48.59 14.3672 42.5305 15.129C45.1364 13.1627 48.4132 12.1544 52.361 12.1042C57.3699 12.8749 62.1592 12.8749 66.7287 12.1042Z" fill="#D3484F" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M100.001 6.05448C104.957 5.62511 109.746 6.12934 114.369 7.56686C114.841 21.7127 115.598 35.8282 116.638 49.9137C115.046 50.1398 113.534 49.8877 112.1 49.1575C111.163 45.8116 109.398 43.039 106.807 40.8394C105.249 41.3477 104.493 42.356 104.538 43.8641C103.538 45.7055 102.53 47.7219 101.514 49.9137C100.004 35.834 99.4997 21.7184 100.001 7.56686C100.001 7.06278 100.001 6.55856 100.001 6.05448Z" fill="#F7DEDF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.076 12.1042C110.569 19.3718 111.073 26.9337 110.588 34.79C108.572 34.79 106.555 34.79 104.538 34.79C104.538 27.228 104.538 19.6661 104.538 12.1042C106.051 12.1042 107.563 12.1042 109.076 12.1042Z" fill="#D34746" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M109.076 12.1041C107.563 12.1041 106.051 12.1041 104.538 12.1041C104.538 19.666 104.538 27.2279 104.538 34.7899C103.306 26.9545 102.297 18.8883 101.514 10.5917C104.409 10.1614 106.93 10.6657 109.076 12.1041Z" fill="#F4B8BD" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M131.761 1.51733C131.761 2.02141 131.761 2.52564 131.761 3.02972C130.828 3.19714 130.072 3.70122 129.493 4.54211C128.737 18.1442 128.486 31.7557 128.737 45.3765C127.728 45.8806 126.721 46.3848 125.712 46.8889C125.712 45.8806 125.712 44.8724 125.712 43.8641C125.712 30.2527 125.712 16.6412 125.712 3.02972C127.728 2.52564 129.745 2.02141 131.761 1.51733Z" fill="#C75351" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4642 19.666C95.9381 23.8204 95.4339 27.8533 93.9518 31.7651C85.0092 34.0175 75.9349 35.5299 66.7288 36.3023C66.7288 30.7568 66.7288 25.2115 66.7288 19.666C66.7288 17.1453 66.7288 14.6248 66.7288 12.1041C75.8027 10.8403 84.877 10.0841 93.9518 9.83551C95.3479 12.922 95.8519 16.1989 95.4642 19.666Z" fill="#F5DFDC" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M72.7783 15.1287C78.4135 14.6408 83.9589 15.145 89.4146 16.6411C84.2123 19.2477 78.6668 20.256 72.7783 19.6658C72.7783 18.1535 72.7783 16.6411 72.7783 15.1287Z" fill="#DA5856" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.712 43.8643C125.712 44.8726 125.712 45.8807 125.712 46.889C124.199 46.889 122.687 46.889 121.175 46.889C120.673 32.7375 121.178 18.6219 122.687 4.54224C123.696 17.8877 124.703 30.9949 125.712 43.8643Z" fill="#F7EDEA" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8526 22.6907C84.8774 22.6907 87.9022 22.6907 90.927 22.6907C90.927 24.7071 90.927 26.7238 90.927 28.7402C84.8485 28.9607 78.799 29.4648 72.7783 30.2526C72.7783 28.2361 72.7783 26.2195 72.7783 24.2031C76.1591 24.6488 79.1839 24.1447 81.8526 22.6907Z" fill="#D34C47" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M62.1916 25.7154C63.7741 35.743 64.0263 45.8256 62.9478 55.9632C61.4183 57.2284 59.654 57.7326 57.6544 57.4755C57.6544 52.9384 57.6544 48.4012 57.6544 43.8641C57.6544 39.3269 57.6544 34.7898 57.6544 30.2526C53.2906 29.7892 49.2575 30.2933 45.5553 31.765C44.0578 41.058 43.5537 50.6364 44.043 60.5003C42.5306 60.5003 41.0182 60.5003 39.5058 60.5003C39.5058 45.8805 39.5058 31.2609 39.5058 16.6411C41.0055 16.9006 42.0138 16.3964 42.5306 15.1287C48.5901 14.3669 54.6396 14.115 60.6792 14.3725C62.0933 17.9729 62.5974 21.7539 62.1916 25.7154Z" fill="#F6DEDE" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.5554 19.6661C49.5885 19.6661 53.6214 19.6661 57.6545 19.6661C57.9116 21.6657 57.4074 23.43 56.1421 24.9595C52.6488 25.7105 49.1198 25.9627 45.5554 25.7157C45.5554 23.6992 45.5554 21.6826 45.5554 19.6661Z" fill="#DA5150" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2829 30.2526C10.6683 31.6659 8.65167 32.17 6.23337 31.765C6.23337 30.2526 6.23337 28.7402 6.23337 27.2278C7.84799 25.8145 9.86461 25.3104 12.2829 25.7154C12.2829 27.2278 12.2829 28.7402 12.2829 30.2526Z" fill="#DB585A" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.5554 31.7649C49.2576 30.2932 53.2907 29.7891 57.6545 30.2525C57.6545 34.7897 57.6545 39.3268 57.6545 43.864C56.1828 40.1618 55.6787 36.1287 56.1421 31.7649C53.1173 31.7649 50.0926 31.7649 47.0678 31.7649C46.5637 31.7649 46.0595 31.7649 45.5554 31.7649Z" fill="#CA6266" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.82 22.6909C18.0119 35.257 18.516 47.8603 18.3324 60.5006C18.3324 61.5089 17.8283 62.0129 16.82 62.0129C16.82 52.4345 16.82 42.856 16.82 33.2776C12.7869 33.2776 8.75402 33.2776 4.72094 33.2776C4.31592 30.8593 4.82 28.8427 6.23332 27.2281C6.23332 28.7405 6.23332 30.2528 6.23332 31.7652C8.65163 32.1702 10.6682 31.6662 12.2829 30.2528C14.9386 28.6617 15.9469 26.141 15.3076 22.6909C10.7705 22.6909 6.23332 22.6909 1.69617 22.6909C5.7359 21.4842 10.0209 20.4759 14.5514 19.6661C16.1091 20.1745 16.8652 21.1828 16.82 22.6909Z" fill="#F9C7CA" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M145.373 6.05449C142.307 5.80933 139.282 6.0613 136.298 6.81068C134.071 7.88387 133.062 9.64822 133.274 12.104C136.802 12.104 140.332 12.104 143.86 12.104C143.86 13.6164 143.86 15.1288 143.86 16.6412C140.332 16.6412 136.802 16.6412 133.274 16.6412C133.274 24.2031 133.274 31.7651 133.274 39.327C136.976 40.7987 141.01 41.3028 145.373 40.8394C145.373 42.3518 145.373 43.8641 145.373 45.3765C140.291 45.5518 135.25 46.0559 130.249 46.8889C130.249 45.8806 129.745 45.3765 128.737 45.3765C128.485 31.7557 128.737 18.1442 129.493 4.54211C130.072 3.70122 130.828 3.19714 131.761 3.02972C136.271 2.32964 140.808 1.82556 145.373 1.51733C145.373 3.02972 145.373 4.54211 145.373 6.05449Z" fill="#F4E6E5" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4067 18.1537C30.3818 17.7916 33.1545 18.2957 35.7248 19.6661C36.4807 33.2682 36.7327 46.8796 36.481 60.5005C34.9686 60.5005 33.4562 60.5005 31.9438 60.5005C32.3895 57.1197 31.8855 54.0949 30.4314 51.4262C28.9191 51.4262 27.4067 51.4262 25.8943 51.4262C25.8943 54.4509 25.8943 57.4757 25.8943 60.5005C22.9132 47.4871 21.9051 33.8757 22.8695 19.6661C24.2171 18.7438 25.7294 18.2397 27.4067 18.1537Z" fill="#F1EAE6" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4067 24.2031C28.479 22.8359 29.9914 22.3318 31.9439 22.6907C31.9439 30.7567 31.9439 38.8228 31.9439 46.8888C29.9274 46.8888 27.9108 46.8888 25.8943 46.8888C25.4096 39.0326 25.9137 31.4707 27.4067 24.2031Z" fill="#DE7C80" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.712 3.02962C125.712 16.6411 125.712 30.2526 125.712 43.864C124.703 30.9947 123.696 17.8874 122.687 4.542C121.178 18.6217 120.673 32.7373 121.175 46.8888C122.687 46.8888 124.199 46.8888 125.712 46.8888C125.712 47.3929 125.712 47.8971 125.712 48.4012C123.696 48.4012 121.678 48.4012 119.662 48.4012C119.662 33.7814 119.662 19.1618 119.662 4.542C121.278 3.12868 123.294 2.6246 125.712 3.02962Z" fill="#FAB5B9" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4642 19.6661C96.4725 19.6661 97.4806 19.6661 98.4889 19.6661C98.4889 30.2528 98.4889 40.8395 98.4889 51.4262C97.4806 51.4262 96.4725 51.4262 95.4642 51.4262C95.4642 46.385 95.4642 41.3436 95.4642 36.3024C85.5548 36.3904 75.9763 37.9028 66.7288 40.8395C66.7288 39.3272 66.7288 37.8148 66.7288 36.3024C75.9349 35.53 85.0092 34.0176 93.9518 31.7652C95.4339 27.8534 95.9381 23.8205 95.4642 19.6661Z" fill="#CB595E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4067 24.2031C29.38 27.0724 30.3882 30.6013 30.4314 34.7898C29.9274 38.3187 29.4231 41.8477 28.9191 45.3765C27.4168 38.3899 26.9127 31.3321 27.4067 24.2031Z" fill="#DD3038" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4642 51.4261C94.3919 52.7933 92.8795 53.2974 90.927 52.9385C89.6658 51.0512 87.6494 50.0429 84.8775 49.9137C80.8297 50.4234 76.7968 50.9275 72.7784 51.4261C72.7784 52.9385 72.7784 54.4509 72.7784 55.9633C70.7619 55.9633 68.7453 55.9633 66.7288 55.9633C66.7288 50.922 66.7288 45.8806 66.7288 40.8394C75.9763 37.9027 85.5548 36.3903 95.4642 36.3022C95.4642 41.3435 95.4642 46.3849 95.4642 51.4261Z" fill="#F3E3D9" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8526 40.8392C85.0347 40.3872 88.0595 40.8913 90.927 42.3516C90.6104 43.2379 90.1063 43.9941 89.4146 44.6202C83.9013 45.7075 78.3558 46.4637 72.7783 46.8888C72.7783 45.3764 72.7783 43.864 72.7783 42.3516C76.1591 42.7973 79.1839 42.2933 81.8526 40.8392Z" fill="#DC555E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8201 22.6907C18.3325 24.7071 19.8448 24.7071 21.3572 22.6907C21.3572 35.7981 21.3572 48.9053 21.3572 62.0127C20.3489 61.5086 19.3408 61.0044 18.3325 60.5003C18.5161 47.8601 18.012 35.2568 16.8201 22.6907Z" fill="#CF4C4F" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M133.274 39.327C137.838 39.0781 142.375 39.3301 146.885 40.0832C149.316 41.6697 150.323 43.9383 149.91 46.889C143.135 48.647 136.581 48.647 130.249 46.889C135.25 46.0559 140.291 45.5519 145.373 45.3766C145.373 43.8642 145.373 42.3518 145.373 40.8394C141.01 41.3028 136.976 40.7987 133.274 39.327Z" fill="#D45052" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8696 19.6661C21.9051 33.8758 22.9133 47.4872 25.8943 60.5006C25.4086 62.8419 23.8962 63.8502 21.3572 63.5253C21.3572 63.0213 21.3572 62.517 21.3572 62.0129C21.3572 48.9056 21.3572 35.7983 21.3572 22.6909C21.0977 21.1912 21.6019 20.1829 22.8696 19.6661Z" fill="#FCDCD6" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M119.662 4.54211C119.662 19.1619 119.662 33.7815 119.662 48.4013C121.678 48.4013 123.696 48.4013 125.712 48.4013C128.044 49.6123 130.564 50.1163 133.274 49.9137C133.274 51.4261 133.274 52.9384 133.274 54.4508C123.868 52.1724 114.29 51.1643 104.538 51.4261C104.538 48.9054 104.538 46.3848 104.538 43.8641C104.493 42.356 105.249 41.3477 106.807 40.8394C109.398 43.039 111.163 45.8116 112.1 49.1575C113.534 49.8877 115.046 50.1398 116.637 49.9137C115.598 35.8282 114.841 21.7127 114.369 7.56688C109.746 6.12935 104.957 5.62512 100.001 6.05449C104.826 4.28243 109.867 2.77004 115.125 1.51733C114.976 5.67277 116.489 6.68107 119.662 4.54211Z" fill="#D74642" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.69616 22.6907C6.23331 22.6907 10.7705 22.6907 15.3076 22.6907C15.9469 26.1407 14.9386 28.6614 12.2829 30.2526C12.2829 28.7402 12.2829 27.2278 12.2829 25.7154C9.86455 25.3104 7.84794 25.8145 6.23331 27.2278C4.81999 28.8425 4.31591 30.8591 4.72093 33.2774C8.75401 33.2774 12.7869 33.2774 16.82 33.2774C16.82 42.8558 16.82 52.4343 16.82 62.0127C14.3377 54.8268 12.8253 47.2648 12.2829 39.3269C12.1014 37.6842 11.0931 36.676 9.25809 36.3021C7.87213 36.3667 6.86383 36.871 6.23331 37.8145C4.21685 45.3765 4.21685 52.9384 6.23331 60.5003C6.23331 62.0127 6.23331 63.5251 6.23331 65.0375C4.4065 66.1477 2.38989 66.6518 0.183769 66.5499C-0.310932 51.6562 0.193146 37.0366 1.69616 22.6907Z" fill="#EFECE7" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.23326 37.8147C6.23326 44.8725 6.23326 51.9302 6.23326 58.9881C8.24973 58.9881 10.2663 58.9881 12.2828 58.9881C10.6682 60.4014 8.65157 60.9055 6.23326 60.5005C4.2168 52.9386 4.2168 45.3766 6.23326 37.8147Z" fill="#DB8A8B" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2829 39.327C12.2829 45.8806 12.2829 52.4344 12.2829 58.988C10.2664 58.988 8.24983 58.988 6.23337 58.988C6.23337 51.9302 6.23337 44.8725 6.23337 37.8146C6.86388 36.8711 7.87219 36.3668 9.25814 36.3022C11.0931 36.6761 12.1014 37.6843 12.2829 39.327Z" fill="#DD393B" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4315 51.4261C31.8855 54.0948 32.3896 57.1196 31.9439 60.5004C33.4563 60.5004 34.9687 60.5004 36.4811 60.5004C35.4088 61.8676 33.8964 62.3717 31.9439 62.0128C30.9356 62.0128 30.4315 61.5087 30.4315 60.5004C30.4315 57.4756 30.4315 54.4509 30.4315 51.4261Z" fill="#E99095" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M66.7287 19.6661C66.7287 25.2116 66.7287 30.7569 66.7287 36.3024C66.7287 37.8148 66.7287 39.3272 66.7287 40.8395C66.7287 45.8808 66.7287 50.9222 66.7287 55.9634C68.7452 55.9634 70.7618 55.9634 72.7783 55.9634C72.7783 54.451 72.7783 52.9386 72.7783 51.4262C76.7967 50.9276 80.8296 50.4235 84.8774 49.9139C87.6493 50.043 89.6657 51.0513 90.9269 52.9386C88.2615 53.1544 85.7408 52.6504 83.365 51.4262C82.3567 52.9386 81.3485 54.451 80.3402 55.9634C78.792 53.9168 76.7755 52.9087 74.2907 52.9386C74.2907 54.451 74.2907 55.9634 74.2907 57.4758C68.5946 57.8014 63.5532 59.818 59.1668 63.5253C57.7622 61.7274 57.2582 59.7108 57.6544 57.4758C59.6539 57.7329 61.4183 57.2287 62.9478 55.9634C64.0262 45.8259 63.7741 35.7433 62.1916 25.7157C62.9812 23.1157 64.4936 21.0991 66.7287 19.6661Z" fill="#D84246" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2829 39.3271C12.8254 47.2651 14.3378 54.827 16.8201 62.0129C16.6526 62.9467 16.1486 63.7029 15.3077 64.2815C12.3242 65.0309 9.29943 65.2829 6.23337 65.0377C6.23337 63.5253 6.23337 62.0129 6.23337 60.5006C8.65167 60.9056 10.6683 60.4015 12.2829 58.9882C12.2829 52.4345 12.2829 45.8808 12.2829 39.3271Z" fill="#F4CDC3" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.5059 16.6412C39.5059 31.2609 39.5059 45.8806 39.5059 60.5004C41.0182 60.5004 42.5306 60.5004 44.043 60.5004C43.5538 50.6364 44.0578 41.058 45.5554 31.765C46.0595 31.765 46.5637 31.765 47.0678 31.765C47.0678 41.8476 47.0678 51.9301 47.0678 62.0127C42.4018 62.0763 38.8728 64.0927 36.4811 68.0623C35.1372 65.7831 33.6248 63.7667 31.9439 62.0127C33.8964 62.3716 35.4088 61.8676 36.4811 60.5004C36.7327 46.8795 36.4808 33.268 35.7249 19.6659C33.1546 18.2956 30.3819 17.7915 27.4068 18.1536C28.1182 16.3485 29.6306 15.3404 31.9439 15.1288C34.526 15.4742 37.0467 15.9783 39.5059 16.6412Z" fill="#D54F49" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.4315 51.4261C30.4315 54.4509 30.4315 57.4756 30.4315 60.5004C28.0901 60.9862 27.0818 62.4986 27.4067 65.0376C24.9509 64.826 23.1865 65.8341 22.1133 68.0623C21.3832 66.6286 21.1311 65.1162 21.3572 63.5252C23.8961 63.85 25.4085 62.8417 25.8943 60.5004C25.8943 57.4756 25.8943 54.4509 25.8943 51.4261C27.4067 51.4261 28.9191 51.4261 30.4315 51.4261Z" fill="#D14E4F" />
                </svg>
            </Box>
        </Slide>
    )
}