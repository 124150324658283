import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const fragmentSrc = [
    "precision mediump float;",
    "uniform vec2      resolution;",
    "uniform float     time;",
    "uniform float     alpha;",
    "uniform vec2      speed;",
    "uniform float     shift;",

    "float rand(vec2 n) {",
    "return fract(cos(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);",
    "}",

    "float noise(vec2 n) {",
    "const vec2 d = vec2(0.0, 1.0);",
    "vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));",
    "return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);",
    "}",

    "float fbm(vec2 n) {",
    "float total = 0.0, amplitude = 0.4;",
    "for (int i = 0; i < 4; i++) {",
    "total += noise(n) * amplitude;",
    "n += n;",
    "amplitude *= 0.6;",
    "}",
    "return total;",
    "}",

    "void main() {",

    "const vec3 c1 = vec3(197.0/255.0, 89.0/255.0, 86.0/255.0);",
    "const vec3 c2 = vec3(199.0/255.0, 81.0/255.0, 72.0/255.0);",
    "const vec3 c3 = vec3(0.0, 0.0, 0.0);",
    "const vec3 c4 = vec3(200.0/255.0, 200.0/255.0, 200.0/255.0);",
    "const vec3 c5 = vec3(0.6);",
    "const vec3 c6 = vec3(0.9);",

    "vec2 p = gl_FragCoord.xy * 10.0 / resolution.xx;",
    "float q = fbm(p - time * 0.1);",
    "vec2 r = vec2(fbm(p + q + time * speed.x - p.x - p.y), fbm(p + q - time * speed.y));",
    "vec3 c = mix(c1, c2, fbm(p + r)) + mix(c3, c4, r.x) - mix(c5, c6, r.y);",
    "float grad = gl_FragCoord.y / resolution.y;",
    "gl_FragColor = vec4(c * cos(shift * gl_FragCoord.y / resolution.y), 1.0);",
    "gl_FragColor.xyz *= 0.6-grad;",
    "float test = 0.6-grad;",
    "float testVal = 0.5;",
    "if(r.x > 0.5 || r.y > 0.5 || fbm(p + r) > 0.5 || test < 0.2 ){",
    "gl_FragColor.w = 0.2;",
    "}",
    "}"
];

const Background = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const gl = canvas.getContext('webgl');

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            gl.viewport(0, 0, canvas.width, canvas.height);
        };

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        const program = gl.createProgram();

        const vshader = gl.createShader(gl.VERTEX_SHADER);
        gl.shaderSource(vshader, 'attribute vec2 pos; void main() { gl_Position = vec4(pos, 0, 1); }');
        gl.compileShader(vshader);
        gl.attachShader(program, vshader);

        const fshader = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fshader, fragmentSrc.join('\n'));
        gl.compileShader(fshader);
        gl.attachShader(program, fshader);

        gl.linkProgram(program);
        gl.useProgram(program);

        const buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            -1, -1,
            -1, 1,
            1, 1,
            1, 1,
            1, -1,
            -1, -1
        ]), gl.STATIC_DRAW);

        const pos = gl.getAttribLocation(program, 'pos');
        gl.enableVertexAttribArray(pos);
        gl.vertexAttribPointer(pos, 2, gl.FLOAT, false, 0, 0);

        const resolutionLocation = gl.getUniformLocation(program, 'resolution');
        const timeLocation = gl.getUniformLocation(program, 'time');
        const shiftLocation = gl.getUniformLocation(program, 'shift');
        const alphaLocation = gl.getUniformLocation(program, 'alpha');
        const speedLocation = gl.getUniformLocation(program, 'speed');

        let time = 0;
        const shift = 1.6;
        const speed = { x: 0.7, y: 0.4 };
        const alpha = 1.0;

        function render() {
            time += 0.01;

            gl.uniform2f(resolutionLocation, canvas.width, canvas.height);
            gl.uniform1f(timeLocation, time);
            gl.uniform1f(shiftLocation, shift);
            gl.uniform1f(alphaLocation, alpha);
            gl.uniform2f(speedLocation, speed.x, speed.y);

            gl.drawArrays(gl.TRIANGLES, 0, 6);

            requestAnimationFrame(render);
        }

        render();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return (
        <Box sx={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: -1,
            background: "black",
            top: 0,
            left: 0
        }}>
            <canvas ref={canvasRef} style={{
                border: '1px solid black',
                width: '100%',
                height: '100%'
            }} />
        </Box>
    );
};

export default Background;