import { Box, Button, Fade, Grid } from '@mui/material';
import Background from './components/Background';
import Logo from './assets/logo.png'
import Blog from './components/pages/blog/Blog';
import { useEffect, useRef } from 'react';
import Header from './components/partials/Header';

function App() {
  useEffect(() => {
    // si esta en /qr/ redirigir a instagram
    if (window.location.pathname === "/qr") {
      window.location.href = "https://www.instagram.com/somos_barbarie?igsh=MTY4aHljc20xdm5vMg==";
    }
  })

  const mainRef = useRef(null)

  function CustomBotom(props) {
    const { children, ...other } = props;

    return (
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "transparent",
          borderRadius: "35px",
          padding: "10px 20px",
          border: "2px solid #D34841",
          color: "#D34841",
          fontWeight: "bold",

          '&:hover': {
            backgroundColor: "#D34841",
            color: "black",
          }

        }}
        {...other}
      >
        {children}
      </Button>
    );
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <img src={Logo} alt="logo" style={{
        width: "400px",
        maxWidth: "80%",
        height: "auto",
        marginTop: "50px",
      }} />
      <Box sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "space-between",
        gap: { xs: "40px", sm: "70px" },
        marginTop: "90px",
      }}>
        <CustomBotom onClick={() => window.open("https://www.instagram.com/somos_barbarie?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==", "_blank")}>
          Instagram
        </CustomBotom>
        <CustomBotom onClick={() => window.open("https://www.youtube.com/@somosbarbarie", "_blank")}>
          Youtube
        </CustomBotom>
        <CustomBotom onClick={() => window.open("https://wa.me/5493534414963?text=Hola%20Barbarie!!%20Quiero%20saber%20información%20sobre...", "_blank")}>
          WhatsApp
        </CustomBotom>

      </Box>
      {/* <Grid container
        ref={mainRef}
        sx={{
          width: "600px",
          maxWidth: "100%",
          justifyContent: "center",
          paddingTop: "130px",
          overflow: "auto",
          height: "100vh",
        }}>
        <Logo />
        <Blog />
      </Grid> */}
      <Header mainRef={mainRef} />
      <Background />
    </div>
  );
}

export default App;